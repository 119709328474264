import { Card, Glimmer, Heading } from "@jobber/components";
import { Divider } from "@jobber/components/Divider";
import { StarGroupLoader } from "jobber/reviews/views/ReviewsPage/components/StarGroup/StarGroup.loader";
import { INDUSTRY_AVERAGES } from "~/jobber/marketingSuiteExpansion/ReviewsEngagement/IndustryAverages";
import { SplitNames, useFeatureFlag, withSplitClient } from "utilities/split";
import { Industry } from "~/utilities/API/graphql";
import styles from "./ReviewsEngagement.module.css";

interface ReviewsEngagementProps {
  accountRating: number;
  accountNumReviews: number;
  industry: Industry;
  isLoading: boolean;
}
function ReviewsEngagement({
  accountRating,
  accountNumReviews,
  industry,
  isLoading,
}: ReviewsEngagementProps) {
  const reviewsComparisonEnabled = useFeatureFlag(
    SplitNames.ReviewsIndustryComparison,
  );
  const industryRating =
    INDUSTRY_AVERAGES[industry].rating || INDUSTRY_AVERAGES.OTHER.rating;
  const industryNumReviews =
    INDUSTRY_AVERAGES[industry].numReviews ||
    INDUSTRY_AVERAGES.OTHER.numReviews;
  const header =
    industry === Industry.OTHER
      ? "Comparison to industry"
      : `Comparison to the ${INDUSTRY_AVERAGES[industry].name} industry`;
  const accountClass =
    accountRating >= industryRating
      ? styles.greenBackground
      : styles.redBackground;
  const industryClass =
    industryRating > accountRating
      ? styles.greenBackground
      : styles.redBackground;
  if (!reviewsComparisonEnabled) return <></>;
  if (isLoading) return <Glimmer />;
  return (
    <Card header={header} accent="greyBlue">
      <div className={styles.container}>
        <div className={styles.flexChild}>
          <Heading level={3}>Your average</Heading>
          <span className={styles.inline}>
            <StarGroupLoader rating={accountRating} />
            <div className={accountClass}>
              <Heading level={4}>{accountRating.toFixed(1)}</Heading>
            </div>
          </span>
          <Heading level={4}>Total reviews: {accountNumReviews}</Heading>
        </div>
        <Divider direction="vertical" size="large" />
        <div className={styles.flexChild}>
          <Heading level={3}>Industry average</Heading>
          <span className={styles.inline}>
            <StarGroupLoader rating={industryRating} />
            <div className={industryClass}>
              <Heading level={4}>{industryRating}</Heading>
            </div>
          </span>
          <Heading level={4}>Total reviews: {industryNumReviews}</Heading>
        </div>
      </div>
    </Card>
  );
}
const SplitWrappedComponent = withSplitClient(ReviewsEngagement);
export { SplitWrappedComponent as ReviewsEngagement };
